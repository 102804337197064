@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/*********************************************************************************************************************************************************** Eigen styles **/
.alata {
  font-family: "Alata", sans-serif;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.container-small {
  width: 50vw;
  margin: 50px auto 50px auto;
}
.container-small h1, .container-small p {
  text-align: center;
  padding-bottom: 100px;
}
.container-small p {
  font-size: 1.75em;
  line-height: 1.75em;
}

.nopadding {
  padding: 0;
}

.nomargin {
  margin: 0;
}

.pb150 {
  padding-bottom: 150px;
}

.pb100 {
  padding-bottom: 100px;
}

.pb75 {
  padding-bottom: 75px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb10 {
  padding-top: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.vw90 {
  width: 90vw;
}

.vw50 {
  width: 50vw;
}

td {
  padding: 5px 0;
}

.pb {
  padding-bottom: 50px;
}

.pt {
  padding-top: 50px;
}

/*Smartphone menu*/
@media all and (max-width: 700px) {
  .container {
    width: 90vw;
  }
  .c-projects {
    margin-top: -7rem;
  }
}
/* Tablets Samsung */
@media all and (min-width: 701px) and (max-width: 768px) {
  .c-projects {
    margin-top: -7rem;
  }
}
/* Tablets */
@media (min-width: 768px) and (max-width: 1199) {
  .c-projects {
    margin-top: -7rem;
  }
}
@media (min-width: 768px) and (max-width: 1199) and (min-width: 768px) and (max-width: 1000px) {
  .c-projects {
    margin-top: -7rem;
  }
}
/* Desktop menu */
@media all and (min-width: 1200px) {
  .active-link::before {
    content: "";
    border-bottom: 2px solid red;
    position: absolute;
    width: 50px;
  }
  .active-link::after {
    content: " ■";
    color: red;
    font-size: 0.8em;
  }
}